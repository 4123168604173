import React, {useEffect,useState} from 'react'
import {Switch} from "@headlessui/react";
import axios from "axios";
import {Link} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default  function InfoAssignment({assignment, getLtik}){
    const [enabled, setEnabled] = useState(false)


    const handle_Change = async(value)=>{
        try{
            let result =await axios.post('/lti/setgradetest',{
                assignment_id:assignment.id,
                course_id:assignment.course_id,
                grade_test: value
            }, {
                headers:{
                    Authorization: 'Bearer ' + getLtik()
                }
            })
            console.log(result.data)
            setEnabled(value)
        }catch(err){
            toast.error(err.response.data)
            console.log(err)
        }


    }

    useEffect(()=>{
        let getGradeTestInfo = async()=>{
            console.log(assignment.id)
            if(assignment){
                let _assignment = await axios.get(`/lti/gradetestinfo?assignment_id=${assignment.id}&course_id=${assignment.course_id}`, {
                    headers:{
                        Authorization: 'Bearer ' + getLtik()
                    }
                })
                if(_assignment.data ){
                    setEnabled(_assignment.data.grade_test)
                }
            }

        }
        getGradeTestInfo()
    }, [assignment])


    return <tr key={assignment.id} className={'border-b'}>
        <td className={'px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'}>
            {assignment.name}
        </td>
        <td>
            <Switch
                checked={enabled}
                onChange={handle_Change}
                className={`${
                    enabled ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
                <span className="sr-only">Enable</span>
                <span
                    className={`${
                        enabled ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white`}
                />
            </Switch>
        </td>
        <td>
            {enabled &&<Link to={{
                pathname: `./sync/${assignment.id}`,
                search: document.location.search

            }} className={'px-3 py=2 bg-indigo-600 text-white rounded-md'}>
                Sync
            </Link>}
        </td>
        <ToastContainer />
    </tr>
}
