import React, {useEffect, useState} from 'react'
import axios from "axios";
import {Listbox} from "@headlessui/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link, useParams} from "react-router-dom";
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
export default function SyncGrade(){

    const getLtik = () => {
        const searchParams = new URLSearchParams(window.location.search)
        const ltik = searchParams.get('ltik')
        if (!ltik) throw new Error('Missing lti key.')
        return ltik
    }
    const pramas = useParams()
     const [listConfigScore,setListConfigScore] = useState(null)
    const [semeter,setSemeter] = useState(null)
    const [score, setScore] = useState(null)
    const [loading, setLoading] = useState(false)

    const errorPrompt = async (message) => {
        toast.error(message)
    }


    const handle_sync_grade_test =async ()=>{
        try{
            setLoading(true)
            if(semeter && semeter.semesterId && score && score.id){
                let result = await axios.post('/lti/syncscoretest', {
                    assignment_id: pramas.assignment_id,
                    semester_id: semeter.semesterId,
                    score_id:score.id
                }, {
                    headers:{
                        Authorization:`Bearer ${getLtik()}`
                    }
                })
                toast.success('sync grade successful')
                setLoading(false)
            }else{
                toast.info('form upload invalidate')
                setLoading(false)
            }
        }catch(err){
            toast.error("can't sync grade for this assignment")
        }


    }

    useEffect(()=>{
        let get_config_score = async()=>{
            try{
                setLoading(true)
                let _listConfigScore = await axios.get(`/lti/list_config_score`, {
                    headers:{
                        Authorization:`Bearer ${getLtik()}`
                    }
                })
                if(_listConfigScore.data.results && _listConfigScore.data.results.length> 0){
                    setSemeter(_listConfigScore.data.results[0])
                }

                setListConfigScore(_listConfigScore.data)
                setLoading(false)
            }catch(err){
                errorPrompt("This course can't sync to EcoStudy")
                setLoading(false)
            }

        }
        get_config_score()

    }, [])


    useEffect(()=>{
        if(semeter){
            setScore(semeter.score[0])
        }
    }, [semeter])

    return <div>
        <div>
            <Link to={{
                pathname: `/lti`,
                search: document.location.search
            }} className={'underline text-indigo-700'}>Back</Link>
        </div>
        <div className={'mt-5'}>
            { listConfigScore && <div className="w-full">
                <div className="flex items-center mb-6">
                    <div className="w-1/3">
                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 justify-items"
                               htmlFor="inline-full-name">
                            Semeter Name:
                        </label>
                    </div>
                    <div className="w-2/3">
                        <Listbox value={semeter} onChange={setSemeter}>
                            <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="block truncate">{semeter.semesterName}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <SelectorIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                      />
                                    </span>
                                </Listbox.Button>
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full z-50 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {listConfigScore.results.map((semester, personIdx) => (
                                            <Listbox.Option
                                                key={personIdx}
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                        active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                    }`
                                                }
                                                value={semester}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                  <span
                                                      className={`block truncate ${
                                                          selected ? 'font-medium' : 'font-normal'
                                                      }`}
                                                  >
                                                    {semester.semesterName}
                                                  </span>
                                                    {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                            </div>
                        </Listbox>
                    </div>
                </div>
                <div className="flex items-center mb-6">
                    <div className="w-1/3">
                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 justify-items"
                               htmlFor="inline-full-name">
                            Score:
                        </label>
                    </div>
                    <div className="w-2/3">
                        <Listbox value={score} onChange={setScore}>
                            <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    { score &&<span className="block truncate">{score.name}</span>}
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <SelectorIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                      />
                                    </span>
                                </Listbox.Button>
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {semeter.score.map((score, personIdx) => (
                                        <Listbox.Option
                                            key={personIdx}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                }`
                                            }
                                            value={score}
                                        >
                                            {({ selected }) => (
                                                <>
                                                  <span
                                                      className={`block truncate ${
                                                          selected ? 'font-medium' : 'font-normal'
                                                      }`}
                                                  >
                                                    {score.name}
                                                  </span>
                                                    {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </div>
                        </Listbox>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="w-1/3"></div>
                    <div className="w-2/3">
                        <button
                            disabled={loading}
                            className={`shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded ${loading?"hover:cursor-pointer":""}`}
                            type="button"
                            onClick={handle_sync_grade_test}
                        >
                            Sync Grade
                        </button>
                    </div>
                </div>
            </div>}
        </div>
        <ToastContainer />
    </div>

}
