import './App.css';
import { Switch, Listbox } from '@headlessui/react'
import {useEffect, useState} from "react";
import axios from 'axios'
import InfoAssignment from "./components/InfoAssignment";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import SyncGrade from "./pages/SyncGrade";

function App() {


  return (
          <BrowserRouter>
              <Routes>
                  <Route  path={'/lti'} element={<Home/>}/>
                  <Route path={'/lti/sync/:assignment_id'} element={<SyncGrade/>} />
              </Routes>
          </BrowserRouter>

  );
}

export default App;
